/** @jsx jsx */
import { jsx } from "@emotion/core"
import Layout from "../components/layout"
import Card, { CardTitle } from "../components/card"

import Profile from "../components/profile"

export default function Contact() {
  return (
    <Layout>
      <Card>
        <CardTitle>Hi, I'm Leo!</CardTitle>
        <Profile />
        <h4>Leave me a message :)</h4>
        <p>
          Either email me at{" "}
          <span css={{ color: "#519657" }}>
            l e o . c r i s t o f a n i @ g m a i l . c o m
          </span>
          , check out my{" "}
          <a href="https://github.com/leocristofani">Github profile</a> or use
          the form below.
        </p>
        <form
          name="contact"
          method="POST"
          action="/contact-success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <label htmlFor="name" placeholder="John Doe">
              Name
            </label>
            <input type="text" id="name" name="name" required />
          </div>
          <div>
            <label htmlFor="email" placeholder="john.doe@gmail.com">
              Email
            </label>
            <input type="email" id="email" name="email" required />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea rows="5" id="message" name="message" required />
          </div>
          <button
            css={{
              display: "inline-block",
              marginTop: 16,
              padding: 12,
              lineHeight: 1,
              paddingLeft: 16,
              paddingRight: 16,
              fontSize: 16,
              cursor: "pointer",
              border: `1px #66bb6a solid`,
              background: "#81c784",
              "&:hover": {
                background: "#66bb6a",
                textDecoration: "none",
              },
              color: "#FFFFFF",
              textDecoration: "none",
              borderRadius: 3,
            }}
            type="submit"
          >
            Send
          </button>
        </form>
      </Card>
    </Layout>
  )
}
