/** @jsx jsx */
import { jsx } from "@emotion/core"

export default function Profile() {
  return (
    <div
      css={{
        marginTop: 24,
        "@media (min-width: 560px)": {
          display: "flex",
        },
      }}
    >
      <img
        css={{
          width: 160,
          height: 160,
          marginRight: 32,
          borderRadius: 160,
          border: `1px #eaeaea solid`,
        }}
        src="https://avatars0.githubusercontent.com/u/830217?s=460&v=4"
        alt="Leonardo Cristofani"
      />
      <div css={{ flex: 1 }}>
        <p>I'm the father of two girls and I live in São Paulo, Brazil.</p>
        <p>
          I like to write code for the web, and I strive to make it reliable and
          readable, coz I want my customers to have a good time and my
          colleagues to add new features with confidence.
        </p>
      </div>
    </div>
  )
}
